import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const Hero = ({ className }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      image: file(relativePath: { eq: "showcase-laptop.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background: file(relativePath: { eq: "header-flipped.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <header>
      <div className="relative bg-white overflow-hidden font-sans">
        <BackgroundImage
          Tag="section"
          className={"w-full header-bg-image"}
          fluid={data.background.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <div className="max-w-screen-xl md:w-2/3 lg:w-1/2 flex flex-col items-center">
            <div className="relative z-10 pb-8 sm:pl-2 sm:pb-16 md:pb-8 lg:w-full lg:pb-10">
              {/*<svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>*/}
              <main className="mt-10 mx-auto max-w-screen-xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1
                    id="home"
                    className=" highlight lg:py-8 lg:text-4xl text-center tracking-tight leading-10 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-2xl"
                  >
                    <span className="flex flex-col items-center">
                      <span className="px-1 py-2 w-full rounded-md">
                        Increase Product{" "}
                        <span className="underline text-white">Sales</span> &
                      </span>
                      <span className="px- py-2 mt-2 w-5/6 rounded-md">
                        Boost{" "}
                        <span className="underline text-white">Awareness</span>
                      </span>
                    </span>
                  </h1>
                  {/*<p className="mt-3 text-base text-center text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Find{" "}
                  <span className="underline">micro & nano influencers</span>{" "}
                  that align with your brand or product and get the best
                  engagement and conversion possible.
                </p>*/}
                  <p className="mt-3 text-base text-center rounded py-2 text-black sm:mt-5 sm:text-base sm:mx-auto md:mt-5 md:text-sm lg:mx-0">
                    Find <span className="underline">micro influencers</span>{" "}
                    that increases your conversions and boosts your social media
                    presence.
                  </p>
                  <div className="mt-5 sm:mt-8 w-full mx-auto text-center flex justify-center items-center">
                    <div className="mt-3 sm:mt-0 sm:ml-3 flex justify-end w-1/2">
                      <Link
                        to="/lists/"
                        className="gradient w-32 flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                      >
                        View Lists
                      </Link>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="mx-auto sm:w-5/6 lg:w-2/3 mb-4">
              <div className="flex items-center justify-center">
                <a
                  href="https://appsumo.com/products/instagram-micro-influencer-list?utm_source=badge"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src="https://appsumo2nuxt-cdn.appsumo.com/img/as-badge-featured.cf14670.png"
                    alt="AppSumo badge"
                  />
                </a>
              </div>
              <Img
                fluid={data.image.childImageSharp.fluid}
                alt={`list example`}
                className="rounded-md"
              />
            </div>
          </div>
        </BackgroundImage>
      </div>
    </header>
  )
}

export default Hero
