import React, { useState } from "react"
import BlueBlob from "../../blobs/blue-blob.svg"
import GreenBlob from "../../blobs/green-blob.svg"
import GradientBlob from "../../blobs/gradient-blob.svg"
import instagram from "../../logos/instagram.svg"
import reddit from "../../logos/reddit.svg"
import tiktok from "../../logos/tiktok.svg"
import twitch from "../../logos/twitch.svg"
import twitter from "../../logos/twitter.svg"
import youtube from "../../logos/youtube.svg"

import dress from "../../logos/dress.png"
import gaming from "../../logos/gaming.png"
import healthcare from "../../logos/healthcare.png"
import healthyFood from "../../logos/healthy-food.png"
import lifestyle from "../../logos/lifestyle.png"
import luggage from "../../logos/luggage.png"
import makeup from "../../logos/makeup.png"
import onlineLearning from "../../logos/online-learning.png"
import sport from "../../logos/sport.png"
import startup from "../../logos/startup.png"
import InstagramListInfo from "./instagram-list-info"
import OtherListInfo from "./other-list-info"

const LandingPageList = () => {
  const [step, setStep] = useState("instagram")
  return (
    <section className="bg-grey">
      <form name="platform-interest" netlify data-netlify="true" hidden>
        <input type="email" name="email" />
        <input type="hidden" name="platform" />
      </form>
      <h2 className="text-center pt-8">Our Lists</h2>
      <div className="flex flex-col relative justify-center mx-auto font-sans">
        <ul className="divide-x-2 flex flex-wrap m-0 h-full my-0 font-sans text-xs md:text-sm leading-snug md:mt-1 mx-16">
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("instagram")}
            style={{
              backgroundColor: step === "instagram" ? "inherit" : "#edf6fe",
              opacity: step === "instagram" ? "1" : "0.5",
              borderBottom: step === "instagram" ? "4px solid #24B47E" : "none",
            }}
          >
            <img
              title="Instagram"
              src={instagram}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">Instagram</p>
          </li>
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("tiktok")}
            style={{
              backgroundColor: step === "tiktok" ? "inherit" : "#edf6fe",
              opacity: step === "tiktok" ? "1" : "0.5",
              borderBottom: step === "tiktok" ? "4px solid #1AAAFF" : "none",
            }}
          >
            <img
              title="TikTok"
              src={tiktok}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">TikTok</p>
          </li>
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("youtube")}
            style={{
              backgroundColor: step === "youtube" ? "inherit" : "#edf6fe",
              opacity: step === "youtube" ? "1" : "0.5",
              borderBottom: step === "youtube" ? "4px solid #24B47E" : "none",
            }}
          >
            <img
              title="YouTube"
              src={youtube}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">YouTube</p>
          </li>
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("reddit")}
            style={{
              backgroundColor: step === "reddit" ? "inherit" : "#edf6fe",
              opacity: step === "reddit" ? "1" : "0.5",
              borderBottom: step === "reddit" ? "4px solid #1AAAFF" : "none",
            }}
          >
            <img
              title="Reddit"
              src={reddit}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">Reddit</p>
          </li>
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("twitch")}
            style={{
              backgroundColor: step === "twitch" ? "inherit" : "#edf6fe",
              opacity: step === "twitch" ? "1" : "0.5",
              borderBottom: step === "twitch" ? "4px solid #24B47E" : "none",
            }}
          >
            <img
              title="Twitch"
              src={twitch}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">Twitch</p>
          </li>
          <li
            className="w-1/6 m-0 pb-2 flex flex-col cursor-pointer hover:opacity-100 hover:underline"
            onClick={() => setStep("twitter")}
            style={{
              backgroundColor: step === "twitter" ? "inherit" : "#edf6fe",
              opacity: step === "twitter" ? "1" : "0.5",
              borderBottom: step === "twitter" ? "4px solid #1AAAFF" : "none",
            }}
          >
            <img
              title="Twitter"
              src={twitter}
              className="mx-auto my-4 h-10 hover:opacity-100"
            />
            <p className="m-0 text-sm md:text-xl text-center">Twitter</p>
          </li>
        </ul>
        <div
          className={`md:w-full md:p-10 transition duration-500 ease-in-out md:py-16 md:rounded-r-lg z-10`}
        >
          {step === "instagram" ? (
            <InstagramListInfo />
          ) : (
            <OtherListInfo product={step} />
          )}
        </div>
      </div>
    </section>
  )
}

export default LandingPageList
