import React, { useState } from "react"
import BlueBlob from "../../blobs/blue-blob.svg"
import GreenBlob from "../../blobs/green-blob.svg"
import GradientBlob from "../../blobs/gradient-blob.svg"
import instagram from "../../logos/instagram.svg"
import reddit from "../../logos/reddit.svg"
import tiktok from "../../logos/tiktok.svg"
import twitch from "../../logos/twitch.svg"
import twitter from "../../logos/twitter.svg"
import youtube from "../../logos/youtube.svg"

import dress from "../../logos/dress.png"
import gaming from "../../logos/gaming.png"
import healthcare from "../../logos/healthcare.png"
import healthyFood from "../../logos/healthy-food.png"
import lifestyle from "../../logos/lifestyle.png"
import luggage from "../../logos/luggage.png"
import makeup from "../../logos/makeup.png"
import onlineLearning from "../../logos/online-learning.png"
import sport from "../../logos/sport.png"
import startup from "../../logos/startup.png"

const WhatsInside = () => {
  const [step, setStep] = useState(0)
  const bg = step === 0 ? "#1bb978" : step === 1 ? "#00bad3" : "#1aaaff"
  return (
    <div className="flex flex-col md:flex-row relative justify-center md:mb-24 md:pt-18 mx-auto font-sans">
      <ul className="divide-y-2 flex flex-wrap md:block m-0 h-full my-0 font-sans text-xs md:text-sm leading-snug md:mt-16">
        <h2 className="text-center font-sans w-full md:text-4xl">
          What's Inside
        </h2>
        <li
          className="py-4 px-4 m-0 cursor-pointer md:hover:shadow-inner hover:opacity-75 hover:underline"
          onClick={() => setStep(0)}
          style={{
            backgroundColor: step === 0 ? bg : "white",
            color: step === 0 ? "white" : "black",
          }}
        >
          <p className="m-0 text-sm md:text-xl text-center">Niches</p>
        </li>
        <li
          className="py-4 px-4 m-0 cursor-pointer md:hover:shadow-inner hover:opacity-75 hover:underline"
          onClick={() => setStep(1)}
          style={{
            backgroundColor: step === 1 ? bg : "white",
            color: step === 1 ? "white" : "black",
          }}
        >
          <p className="m-0 text-sm md:text-xl text-center">Metrics</p>
        </li>
        <li
          className="py-4 px-4 m-0 cursor-pointer md:hover:shadow-inner hover:opacity-75 hover:underline"
          onClick={() => setStep(2)}
          style={{
            backgroundColor: step === 2 ? bg : "white",
            color: step === 2 ? "white" : "black",
          }}
        >
          <p className="m-0 text-sm md:text-xl text-center">Platforms</p>
        </li>
      </ul>
      <div
        className={`md:w-2/3 md:p-10 transition duration-500 ease-in-out md:py-16 md:rounded-r-lg z-10`}
      >
        {step === 0 ? (
          <>
            <div>
              <p className=" p-8 md:p-0 md:w-2/3">
                We categorize each influencer by which niche they usually post
                about and what their audience is interested in. That way you can
                easily choose who to work with based on what their audience is
                interested in.
              </p>
              <div className="mt-2 mx-auto grid grid-cols-3 z-50">
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={dress} className="h-6 mr-2 hover:opacity-75" />
                  <span>Fashion</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={gaming} className="h-6 mr-2 hover:opacity-75" />
                  <span>Gaming</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={healthcare} className="h-6 mr-2 hover:opacity-75" />
                  <span>Health & Wellness</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img
                    src={healthyFood}
                    className="h-6 mr-2 hover:opacity-75"
                  />
                  <span>Food & Bevarage</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={lifestyle} className="h-6 mr-2 hover:opacity-75" />
                  <span>Lifestyle</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={luggage} className="h-6 mr-2 hover:opacity-75" />
                  <span>Travel</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={makeup} className="h-6 mr-2 hover:opacity-75" />
                  <span>Makeup & Skincare</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img
                    src={onlineLearning}
                    className="h-6 mr-2 hover:opacity-75"
                  />
                  <span>Education & Courses</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={sport} className="h-6 mr-2 hover:opacity-75" />
                  <span>Sports & Fitness</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={startup} className="h-6 mr-2 hover:opacity-75" />
                  <span>Stocks & Crypto</span>
                </div>
                <div className="flex flex-column item-center hover:shadow-inner p-1">
                  <img src={lifestyle} className="h-6 mr-2 hover:opacity-75" />
                  <span>Pets</span>
                </div>
              </div>
            </div>
            <img
              src={GradientBlob}
              className="m-0 p-0 md:w-1/3 absolute right-0 bottom-0 opacity-50"
            />
          </>
        ) : step === 1 ? (
          <>
            <div>
              <p>
                We want to make it easy for you to choose the right influencer.
                <br />
                All the data you need to make an informed decision.
              </p>
              <ul className="list-disc">
                <li>Follower Count</li>
                <li>Engagement</li>
                <li>Language</li>
                <li>Categories</li>
                <li>#hashtags</li>
                {/* <li>Country</li>*/}
                {/*<li>Authenticity</li>*/}
                {/* <li>Audience</li>*/}
                {/* <li>IL Rating</li>*/}
                <li>Platforms</li>
              </ul>
            </div>
            <img
              src={GreenBlob}
              className="m-0 p-0 md:w-1/3 absolute right-0 bottom-0 opacity-50"
            />
          </>
        ) : (
          <>
            <div>
              <p className="z-30">
                We want to make it easy for you to choose influencers based on
                your target audience, we have wide net of influencers within a
                variety of platforms. Of course if you prefer to focus on a
                specific platform you can always filter by it as well.
              </p>
              <div className="md:w-2/3 mt-2 mx-auto grid grid-cols-3 z-50">
                <img
                  title="Instagram"
                  src={instagram}
                  className="mx-auto my-4 h-10 hover:opacity-75"
                />
                <img
                  title="TikTok"
                  src={tiktok}
                  className="m-auto p-0 h-10 hover:opacity-75"
                />
                <img
                  title="YouTube"
                  src={youtube}
                  className="mx-auto my-4 h-10 hover:opacity-75"
                />
                <img
                  title="Reddit"
                  src={reddit}
                  className="mx-auto my-4 h-10 hover:opacity-75"
                />
                <img
                  title="Twitch"
                  src={twitch}
                  className="mx-auto my-4 h-10 hover:opacity-75"
                />
                <img
                  title="Twitter"
                  src={twitter}
                  className="mx-auto my-4 h-10 hover:opacity-75"
                />
                {/**
                 * Snapchat
                 * LINE
                 * FaceBook
                 */}
              </div>
            </div>
            <img
              src={BlueBlob}
              className="m-0 p-0 md:w-1/3 absolute right-0 bottom-0 opacity-50 z-0"
            />
          </>
        )}
      </div>
    </div>
  )
}

export default WhatsInside
