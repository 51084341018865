import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Banner from "../components/banner/banner"
import Hero from "../components/hero/hero-influencer"
import HowToUse from "../components/how/how-to-use"
import Why from "../components/why"
import WhatsInside from "../components/whats-inside"
import ProductImage from "../components/product-image/product-image"
import GettingStarted from "../components/how"
import LandingPageList from "../components/lists/landing-page-list"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Influencer List"
      description="Find micro and nano influencers for your marketing campaigns on any platform in any niche."
    />
    <Banner />
    <Hero />
    <LandingPageList />
    <Why />
    <WhatsInside />
  </Layout>
)

export default IndexPage
