import { graphql, div, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import React from "react"

const OtherListInfo = ({ product }) => {
  const data = useStaticQuery(graphql`
    query OtherListQuery {
      list: file(relativePath: { eq: "showcase-list2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      profile: file(relativePath: { eq: "showcase-profile.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="relative flex flex-col">
      <div className="absolute inset-x-0 top-0 h-full flex items-center">
        <div className="mx-auto bg-white z-50 sm:w-4/5 md:w-1/2 md:shadow-custom p-4 md:rounded-md">
          <h2 className="font-normal text-blue my-3 text-center">
            Under Construction{" "}
            <span className="inline-block text-base text-black leading-6 font-normal my-1">
              {" "}
              We are re-making the list for 2021, Making it even better!
            </span>
          </h2>
          <form
            className="w-full max-w-sm py-2 mx-auto"
            name="platform-interest"
            method="POST"
            //@ts-ignore
            data-netlify="true"
          >
            <div className="md:flex items-center">
              <input
                type="hidden"
                className="hidden"
                name="form-name"
                value="notify"
              />
              <input
                className="py-4 md:py-2 appearance-none bg-transparent border-b-2 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                name="email"
                placeholder="Your email"
                aria-label="Email Adress"
              />
              <input
                className="flex-shrink-0 bg-light-blue hover:shadow-xl border-light-blue hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
                type="submit"
                value="Notify Me"
              />
              <input type="hidden" name="platform" value={product} />
            </div>
            <label className="md:w-full block text-gray-500 font-bold mr-3 px-2 py-4">
              <span className="text-sm">Get notified when it's released.</span>
            </label>
          </form>
        </div>
      </div>
      <div
        style={{ color: "transparent", textShadow: "0 0 5px rgba(0,0,0,0.2)" }}
        className="w-full flex"
      >
        <div className="w-1/3">
          <Img
            fluid={data.list.childImageSharp.fluid}
            alt={`list example`}
            className="rounded-md shadow-custom mb-8 opacity-25"
          />
        </div>
        <div className="w-2/3 flex flex-col items-center">
          <div className="flex flex-col justify-around h-full w-2/3">
            <div>
              <h3>Find Content Creators</h3>
              <p>
                Search through the influencers in our lists either by follower
                count, category or engagement. And find your perfect fit.
              </p>
              <div className="bg-gray-400 w-1/3 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out">
                <p
                  style={{
                    color: "transparent",
                    textShadow: "0 0 5px rgba(0,0,0,0.2)",
                  }}
                  className="m-0"
                >
                  View List
                </p>
              </div>
            </div>
            <div>
              <h3>Engagement Metrics</h3>
              <p>
                Detailed influencer data to help you make data driven decision
                based on metrics and insights.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/3 flex items-center justify-center">
          <div className="m-auto">
            <h3>Custom List</h3>
            <p>
              Fine tune your influencer search by creating your own list
              tailored to you unique use case.
            </p>
            <div
              style={{
                color: "transparent !important",
                textShadow: "0 0 5px rgba(0,0,0,0.2)",
              }}
              className="bg-gray-400 w-1/3 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
            >
              <p
                style={{
                  color: "transparent",
                  textShadow: "0 0 5px rgba(0,0,0,0.2)",
                }}
                className="m-0"
              >
                Create List
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/3">
          <Img
            fluid={data.profile.childImageSharp.fluid}
            alt={`list example`}
            className="rounded-md shadow-custom mb-8 w-4/5 m-auto opacity-25"
          />
        </div>
      </div>
    </div>
  )
}

export default OtherListInfo
