import React from "react"
import { Link } from "gatsby"

const Why = () => {
  return (
    <section className="p-8 pb-32 pt-12 map py">
      <div className="mx-auto w-4/5 md:w-1/2">
        <h2 className="font-normal text-2xl text-green my-3">
          80% of marketers find influencer marketing effective
        </h2>
        <p className="font-sans text-gray-600">People trust people, not ads.</p>
        <p className="inline-block text-sm leading-6 font-normal my-1 font-sans">
          Influencer marketing employs, niche content creators to improve brand
          awareness, increase traffic, and drive your business message to your
          target audience. These content creators already market to your ideal
          audience across different channels which allows you to expand your
          reach across your buyer personas.
        </p>
        <p className="inline-block text-sm leading-6 font-normal my-1 font-sans">
          By working with{" "}
          <span
            className="italic"
            title="lower average following but higher engagement rate."
          >
            micro influencers
          </span>{" "}
          you reach out to their tight knit community giving you a higher
          engagement rate and validity as a trusted recommendation.
        </p>
        {/*<button className="md:ml-10 items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-dark-green hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2">
          <Link to="/trial/" state={{ trial: true }}>
            Start now
          </Link>
        </button>*/}
      </div>
    </section>
  )
}

export default Why
